import { Cross } from "@/components/Icon/Cross";
import { useTextConversionMethod } from "@/lib/prismicio/useTextConversion";
import { useFilters, useI18n } from "@jog/react-components";
const parse = (price) => (+price).toFixed(2);
export const Filters = ({ data, onChange }) => {
    const { $t } = useI18n();
    const textConvert = useTextConversionMethod();
    const filters = useFilters(data);
    if (!filters.length)
        return <></>;
    return (<div className="mx-2.5 flex flex-col gap-2.5 py-2.5 lg:flex-row">
            <div className="flex-shrink-0 px-0 py-0 text-base lg:py-1.25">
                <span>{$t("Selected filters")}:</span>
            </div>
            <div className="flex flex-wrap gap-2.5">
                {filters.map((filter, index_1) => {
            const { selectiontype, attributes } = filter;
            if (selectiontype === "slider") {
                const title = `€ ${parse(attributes[0].title)}-${parse(attributes[1].title)}`;
                return <FilterPill key={index_1} title={title} url={attributes[0].url} onChange={onChange}/>;
            }
            else {
                return attributes.map((attribute, index_2) => (<FilterPill key={`${index_1}_${index_2}`} title={textConvert(attribute.title)} url={attribute.url} onChange={onChange}/>));
            }
        })}
            </div>
        </div>);
};
const FilterPill = ({ title, url, onChange }) => (<div className="flex items-center gap-2.5 bg-white py-1.25 px-2.5 text-base shadow">
        <span>{title}</span>
        <Cross onClick={() => onChange(url)} width={20} height={20} className="ml-1 cursor-pointer fill-current text-gray-silver hover:text-gray-dracula"/>
    </div>);
