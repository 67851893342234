import { Arrow } from "@jog/react-components";
export const Sorting = ({ onChange, sortfields, }) => {
    var _a;
    if (!sortfields)
        return <></>;
    return (<div className="relative flex w-full flex-wrap px-0 lg:px-1.75">
            <select className="w-full appearance-none border border-gray-dark px-1.75 py-2 text-center text-xs font-bold uppercase outline-0 lg:w-auto lg:min-w-[250px]" onChange={(event) => onChange(event.target.value)} value={(_a = sortfields === null || sortfields === void 0 ? void 0 : sortfields.find((i) => i.isselected)) === null || _a === void 0 ? void 0 : _a.url}>
                {sortfields.map((item, index) => (<option value={item.url} key={index}>
                        {item.displaytitle}
                    </option>))}
            </select>
            <Arrow color="black" className="absolute right-6.75 top-1/2 z-[1] -translate-y-1/2" direction="down"/>
        </div>);
};
