import { Modal } from "@/components";
import { CheckboxFacet } from "@/components/Facet/CheckboxFacet";
import { LinkFacet } from "@/components/Facet/LinkFacet";
import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { FacetProvider, FacetSkeletons, SliderFacet, useI18n } from "@jog/react-components";
import { useState } from "react";
export const Facets = ({ data, path, pending, onChange, expand, loaded, }) => {
    var _a;
    if (!loaded || !data)
        return <FacetSkeletons />;
    return (<div>
            {(_a = data.facets) === null || _a === void 0 ? void 0 : _a.map((facet) => {
            switch (facet.facetsettings.selectiontype) {
                case "tree":
                case "link":
                    return (<FacetProvider key={facet.facetsettings.urlkey} facet={facet} className="my-1.75 mx-0 text-xs lg:text-13" expand={expand}>
                                <LinkFacet path={path}/>
                            </FacetProvider>);
                case "checkbox":
                    return (<FacetProvider key={facet.facetsettings.urlkey} facet={facet} className="my-1.75 mx-0 text-xs lg:text-13" expand={expand}>
                                <CheckboxFacet pending={pending} onChange={onChange}/>
                            </FacetProvider>);
                case "slider":
                    return (<FacetProvider key={facet.facetsettings.urlkey} facet={facet} className="my-1.75 mx-0 text-xs lg:text-13" expand={expand}>
                                <SliderFacet pageUrl={data.properties.pageurl}/>
                            </FacetProvider>);
            }
            return <></>;
        })}
        </div>);
};
export const MobileFacets = ({ data, path, pending, onChange, }) => {
    var _a, _b;
    const { $t } = useI18n();
    const [modal, setModal] = useState(false);
    return (<>
            <button className="ease my-0 mx-auto block w-full cursor-pointer select-none border border-gray-dark bg-white py-2 px-7.5 text-center align-middle font-primary text-xs font-medium text-black transition-all lg:hidden" onClick={() => setModal(true)}>
                {$t("FILTER")}
            </button>
            {modal && (<Modal hideModal={() => setModal(false)}>
                    <Facets loaded expand={false} data={data} path={path} pending={pending} onChange={onChange}/>
                    <div className="my-3 mx-auto flex justify-center">
                        <PrimaryButton onClickFunction={() => setModal(false)} buttonType="button" className="!px-2.5 text-center">
                            {`${$t("Show products")} (${(_b = (_a = data === null || data === void 0 ? void 0 : data.properties) === null || _a === void 0 ? void 0 : _a.nrofitems) !== null && _b !== void 0 ? _b : ""})`}
                        </PrimaryButton>
                    </div>
                </Modal>)}
        </>);
};
