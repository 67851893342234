import { ListerTile } from "@/components";
import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { ContentLoader, Link, PrismicImage, useI18n, useLastLister } from "@jog/react-components";
import { gtmProductClickOnLister } from "@jog/shared";
import { isFilled } from "@prismicio/helpers";
import { Fragment } from "react";
const Skeleton = () => (<div className="grid grid-cols-2 gap-3.5 px-2.5 lg:grid-cols-4 lg:px-0">
        {Array(20)
        .fill(0)
        .map((_, index) => (<ContentLoader key={index} height="350px"/>))}
    </div>);
const Banner = ({ banner }) => {
    if (!(banner === null || banner === void 0 ? void 0 : banner.enabled))
        return;
    if (!isFilled.image(banner.img))
        return;
    return (<div className="col-span-12">
            <Link to={banner.link} title={banner.img.alt}>
                <PrismicImage className="block w-full" img={banner.img} sizes="100vw"/>
            </Link>
        </div>);
};
export const Products = ({ loaded, data, banners, group, category, }) => {
    const { $t } = useI18n();
    const { recordLister } = useLastLister();
    if (!loaded || !data)
        return <Skeleton />;
    if (data.items.length === 0)
        return (<div className="text-base font-bold">
                <div className="mb-4">{$t("No items found")}</div>
                <Link to="/">
                    <PrimaryButton>{$t("Continue shopping")}</PrimaryButton>
                </Link>
            </div>);
    return (<div className="grid grid-cols-12 gap-3.5 px-2.5 pt-3.75 lg:gap-y-7.5 lg:px-0 lg:pt-0">
            {data.items.map((item, index) => {
            var _a, _b, _c;
            const code = (_c = (_b = (_a = item.attributes) === null || _a === void 0 ? void 0 : _a.find((val) => { var _a; return ((_a = val.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "parent" && val.values; })) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c[0];
            const bannerIndex = [15, 31].findIndex((i) => i === index);
            return (<Fragment key={item.itemno}>
                        <ListerTile className="col-span-6 md:col-span-3" data={item} group={code && code !== "-" ? group === null || group === void 0 ? void 0 : group[code] : []} onClick={(item) => {
                    if (category) {
                        gtmProductClickOnLister(item, category.replace(/^\//, ""));
                    }
                    recordLister(data.properties, item);
                }}/>
                        {banners && <Banner banner={banners[bannerIndex]}/>}
                    </Fragment>);
        })}
        </div>);
};
