import { styled } from "@jog/theming";
import { Fragment } from "react";
import { Handles, Rail, Slider, Tracks } from "react-compound-slider";
const SliderBar = styled.div `
    &:after {
        font-family: ${() => "icomoon-jc"};
        content: "\\e912";
    }
`;
export const PriceSlider = ({ range, values, onUpdate, step, setDrag, }) => (<div className="px-4.5">
        <Slider mode={2} onSlideStart={() => setDrag(true)} onSlideEnd={() => setDrag(false)} step={step} domain={range} onUpdate={onUpdate} className="relative h-[42px] w-full" values={values}>
            <Rail>
                {({ getRailProps }) => (<>
                        <div className="absolute top-1/2 h-[42px] w-full -translate-y-1/2 cursor-pointer" {...getRailProps()}/>
                        <div className="pointer-events-none absolute top-1/2 h-2.5 w-full -translate-y-1/2 border border-gray-silver bg-white"/>
                    </>)}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (<div>
                        {handles.map((handle) => (<Fragment key={handle.id}>
                                <div className="absolute top-1/2 z-[5] h-[42px] w-9 -translate-y-1/2 -translate-x-1/2 cursor-pointer bg-transparent" style={{
                left: `${handle.percent}%`,
                WebkitTapHighlightColor: "rgba(0,0,0,0)",
            }} {...getHandleProps(handle.id)}/>
                                <SliderBar role="slider" aria-valuemin={range[0]} aria-valuemax={range[1]} aria-valuenow={handle.value} className="absolute top-1/2 z-[2] flex h-7 w-9 -translate-y-1/2 -translate-x-1/2 items-center justify-center bg-gray-shuttle after:text-[15px] after:text-gray-main" style={{ left: `${handle.percent}%` }}/>
                            </Fragment>))}
                    </div>)}
            </Handles>
            <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (<div>
                        {tracks.map(({ id, source, target }) => (<div key={id} className="absolute top-1/2 z-[1] h-3.5 -translate-y-1/2 bg-transparent" style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }} {...getTrackProps()}/>))}
                    </div>)}
            </Tracks>
        </Slider>
    </div>);
