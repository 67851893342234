import { getSelectedAttributes, gtmAdpageItemList, gtmPushAllListerProducts, typedBoolean, } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigation } from "./useNavigation";
export const useLister = (navigation, keys, _path, categoryId) => {
    var _a;
    const router = useRouter();
    const { pending, response, navigation: fetch, loaded, group } = useNavigation(navigation);
    const [path, setPath] = useState(_path);
    useEffect(() => {
        if (response === null || response === void 0 ? void 0 : response.items) {
            gtmPushAllListerProducts(response.items, keys.join("/"));
            gtmAdpageItemList(response.items);
        }
    }, [keys, response]);
    const pushHistoryState = (res) => {
        var _a, _b;
        if (!res)
            return;
        const path = res.properties.prettyUrl;
        const temp = new URL(window.location.origin + "/" + path);
        const currentQs = new URLSearchParams(window.location.search);
        const qs = new URLSearchParams(temp.searchParams);
        currentQs.forEach((value, key) => {
            if (["tn_p", "tn_sort"].includes(key))
                return;
            if (!qs.has(key))
                qs.append(key, value);
        });
        const tn_sort = (_b = (_a = res.properties.sortfields) === null || _a === void 0 ? void 0 : _a.find((i) => i.isselected)) === null || _b === void 0 ? void 0 : _b.title;
        if (tn_sort)
            qs.append("tn_sort", tn_sort);
        const tn_p = res.properties.currentpage;
        if (tn_p > 1)
            qs.append("tn_p", `${tn_p}`);
        const pathname = temp.pathname.replace(/\/$/, "");
        const asPath = `${pathname}?${qs}`.replace(/\?$/, "");
        const url = new URL(`${temp.origin}${asPath}`);
        if (url.toString() === window.location.href)
            return;
        window === null || window === void 0 ? void 0 : window.history.pushState({ ...window.history.state, as: asPath }, "", url);
        setPath(pathname);
    };
    const onChange = useCallback((qs, scrollToTop = false) => {
        if (scrollToTop)
            window.scroll({ top: 0, behavior: "smooth" });
        const _qs = new URLSearchParams(qs);
        _qs.set("tn_ps", "32");
        fetch(_qs)
            .then((res) => pushHistoryState(res))
            .catch(() => { });
    }, [fetch]);
    const total = ((_a = response === null || response === void 0 ? void 0 : response.properties) === null || _a === void 0 ? void 0 : _a.nrofitems) || 0;
    const init = useCallback(() => {
        const qs = new URLSearchParams(window.location.search);
        qs.set("tn_ps", "32");
        if (router.asPath.includes("_")) {
            const url = new URL(window.location.origin + router.asPath);
            qs.append("pretty-path", url.pathname.replace(/^\//, ""));
        }
        else {
            qs.set("tn_cid", categoryId);
        }
        fetch(qs)
            .then((res) => pushHistoryState(res))
            .catch(() => { });
    }, [categoryId, fetch, router.asPath]);
    useEffect(() => {
        function onPop(e) {
            if (e.state.as === router.asPath) {
                init();
            }
        }
        window.addEventListener("popstate", onPop);
        return () => {
            window.removeEventListener("popstate", onPop);
        };
    }, [init, router]);
    const session = useSession();
    useEffect(() => {
        if (session.status === "loading")
            return;
        init();
    }, [init, session.status]);
    return {
        total,
        onChange,
        path,
        pending,
        response,
        loaded,
        group,
    };
};
export const useFilters = (data) => useMemo(() => {
    var _a, _b, _c, _d;
    const pageUrl = ((_a = data === null || data === void 0 ? void 0 : data.properties) === null || _a === void 0 ? void 0 : _a.pageurl) || "";
    return (((_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.facets) === null || _b === void 0 ? void 0 : _b.filter((facet) => !["tree", "link"].includes(facet.facetsettings.selectiontype))) === null || _c === void 0 ? void 0 : _c.map((facet) => getSelectedAttributes(facet, pageUrl))) === null || _d === void 0 ? void 0 : _d.filter(typedBoolean)) || []);
}, [data]);
